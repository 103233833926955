body {
  width: auto !important;
}
@media (max-width: 575px) {
  #root {
    padding-bottom: 0!important;
  }
  .validation-page {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow: hidden;
  }
  .validation-page .container-wrapper {
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .validation-page .container-wrapper .container {
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: scroll;
    flex-grow: 1;
    padding-bottom: 20px;
  }
  .validation-page .container-wrapper .container-sms {
    box-shadow: rgba(0, 0, 0, 0.3) 2px -1px 6px 0px;
    z-index: 2;
    background: white;
    padding: 10px 20px;
    overflow: initial;
  }
  .validation-page .container-wrapper .container-sms .cell {
    box-shadow: none;
  }
  .validation-page .cell {
    padding: 15px;
  }
  .validation-page .padding--bottom--20 {
    padding-bottom: 10px;
  }
  .validation-page .padding--bottom--10 {
    padding-bottom: 5px;
  }
  .validation-page .cell h1 {
    font-size: 21px;
  }
  .validation-page .cell h2 {
    font-size: 16px;
  }
  .validation-page .responsive-table .tr {
    padding: 4px 0 !important;
  }
  .validation-page .validation__sign-box {
    padding: 5px !important;
  }
  .validation-page .validation__sign-box .text-right {
    text-align: left !important;
  }
  .validation-page .validation__sign-box .validation__form {
    flex-direction: column;
  }
  .validation-page .validation__sign-box .validation__form--right .validation__form__input {
    width: 100% !important;
    padding: 0!important;
  }
  .validation-page .validation__sign-box .validation__form--right .validation__form__btn {
    width: 100% !important;
  }
  .validation-page .validation__sign-box .validation__form--right .validation__form__btn input.btn {
    width: 100% !important;
  }
}
